import React from 'react';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';

import { withAuthentication } from './Session';

import * as ROUTES from './routes';
import TendieNav from './Navbar';
import HomePage from './Home';
import LandingPage from './LandingPage';
import SignInPage from './Signin';
// import SignUpPage from './Signup';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => (
    <Router>
        <TendieNav />
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route exact path={ROUTES.HOME} component={HomePage} />
        <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
    </Router>
);


export default withAuthentication(App);