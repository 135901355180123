
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// const config = {
//   appId: process.env.REACT_APP_API_ID,
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASE_URL,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
// };

const firebaseConfig = {
  apiKey: "AIzaSyBa-XVhY1s9vsujG1Mssnk21_8SHBrNYDM",
  authDomain: "tendie-city.firebaseapp.com",
  databaseURL: "https://tendie-city-default-rtdb.firebaseio.com",
  projectId: "tendie-city",
  storageBucket: "tendie-city.appspot.com",
  messagingSenderId: "513826322320",
  appId: "1:513826322320:web:bc87f6f3246e67a123e515",
  measurementId: "G-M9VRZHV6W3"
};

class Firebase {
  constructor() {
    console.log("Startup");
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.database();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);


  // *** User API ***
  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');
}

export default Firebase;
