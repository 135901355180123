import logo from './logo.png';
import './App.css';
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

class LandingPage extends Component {
    render() {
        return (
          <div>
            <header className="App-header">
            <h1>TENDIE.CITY</h1>
              <br />
              <img src={logo} className="App-logo" id="tendies" alt="logo" />
            </header>
          </div>
        )
    }
}

export default LandingPage;