import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { AuthUserContext } from './Session';

import {
    Button,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Label,
    Input,
    Nav,
    NavItem,
    NavLink,
    Navbar,
    NavbarBrand,
    NavbarText,
    NavbarToggler,
    UncontrolledDropdown,


} from 'reactstrap';

import { SignUpButton } from './Signup';
import SignOutButton from './Signout';
import * as ROUTES from './routes';

import 'bootstrap/dist/css/bootstrap.min.css';


const TendieNav = () => (
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth /> : <NavigationUnAuth />
      }
    </AuthUserContext.Consumer>
);



function NavigationAuth() {
    let route = useLocation().pathname;
    if ( route != "/home" && route != "/crypto") {
        route = "/home";
    }
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar color="dark" dark expand="md">
            <NavbarBrand href="/" className="fixnav">
                <img
                    alt=""
                    src="/navlogo.png"
                    width="32"
                    height="25"
                    className="d-inline-block align-top"
                />{' '}
                tendie.city
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
                <NavItem>
                    <NavLink href={ROUTES.HOME}>Dashboard</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/dividends/" disabled>Dividends</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/options/" disabled>Options</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/trades/" disabled>Trades</NavLink>
                </NavItem>
            </Nav>
            <Nav navbar className="ml-auto">
                <Form action={route} inline>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Input type="symbol" name="symbol" id="symbol" placeholder="NASDAQ:MSFT" />
                    </FormGroup>
                    <Button>Search</Button>
                </Form>
                <NavbarText>
                    <SignOutButton />
                </NavbarText>
            </Nav>
            </Collapse>
        </Navbar>
    );
}

function NavigationUnAuth() {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar color="dark" dark expand="md">
            <NavbarBrand href="/" className="fixnav">
                <img
                    alt=""
                    src="/navlogo.png"
                    width="32"
                    height="25"
                    className="d-inline-block align-top"
                />{' '}
                tendie.city
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>

            </Nav>
            <Nav navbar>
                <NavItem>
                    <Link to={ROUTES.SIGN_IN}>
                        <Button>
                            Sign In
                        </Button>
                    </Link>
                </NavItem>
                <NavItem>
                    <Link to={ROUTES.SIGN_UP}>
                        <SignUpButton />
                    </Link>
                </NavItem>
            </Nav>
            </Collapse>
        </Navbar>
    );
}


export default TendieNav;