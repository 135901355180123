import React from 'react';
import { useLocation } from 'react-router-dom';
import { AuthUserContext, withAuthorization } from './Session';

import {
    Container,
    Col,
    Jumbotron,
    Row
} from 'reactstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function HomePage() {
    let query = useQuery();

    return (
        <AuthUserContext.Consumer>
            {authUser => (
            <Container fluid className="dark-mode">
                <br />
                <TradingViewer
                    symbol={query.get("name")}
                />
            </Container>
            )}
        </AuthUserContext.Consumer>
    )
}

function TradingViewer({ symbol }) {
    let studies = [
        'Volume@tv-basicstudies',
        'VWAP@tv-basicstudies',
    ]
    return (
      <div>
        {symbol ? (
            <div className="chart">
                <AdvancedRealTimeChart
                    range="1D"
                    studies={studies}
                    symbol={symbol}
                    theme="dark"
                    timezone="America/Chicago"
                    allow_symbol_change
                    autosize
                />
            </div>
        ) : (
            <div className="chart">
                <AdvancedRealTimeChart
                    theme="dark"
                    range="1D"
                    studies={studies}
                    symbol="COINBASE:BTCUSD"
                    timezone="America/Chicago"
                    autosize
                />
            </div>
        )}
      </div>
    );
  }


const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);