import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Alert,
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
} from 'reactstrap';

import { SignUpLink } from './Signup';
import { withFirebase } from './Firebase';
import * as ROUTES from './routes';

const SignInPage = () => (
  <Container fluid className="dark-mode app-mode">
    <Row>
        <Col>
            <h1 className="text-center">Sign In</h1>
            <SignInForm />
            <SignUpLink />
        </Col>
    </Row>
  </Container>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit}>
        { error ? (
            <Alert color="danger">
                <p>{error.message}</p>
            </Alert>
        ): (
            <div></div>
        )}
        <InputGroup>
          <Input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
          />
        </InputGroup>

        <InputGroup>
          <Input
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
            placeholder="Password"
          />
        </InputGroup>

        <InputGroup>
          <Button disabled={isInvalid} className="form-control" type="submit">
            Sign In
          </Button>
        </InputGroup>
      </form>
    );
  }
}

const SignInForm = withRouter(withFirebase(SignInFormBase));

export default SignInPage;

export { SignInForm };